import React, { Fragment, useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Paper, AppBar, Toolbar, IconButton } from "@material-ui/core";
import { Switch, Route, Link, BrowserRouter, Redirect } from "react-router-dom";
import "./App.css";
import Landing from "./pages/landing";
import Home from "./pages/home";
import Revise from "./pages/revise";
import Saved from "./pages/saved";
import Settings from "./pages/settings";
import AccountMenu from "./components/AccountMenu";
import SignUp from "./pages/signup";
import Logo from "./components/Logo";
import { useDispatch } from "react-redux";
import { fetchQuestions } from "./redux/actions";
import Login from "./pages/login";
import Password from "./pages/password";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import MenuIcon from "@material-ui/icons/Menu";
import Sidebar from "./components/Sidebar";
import clsx from "clsx";
import { getSavedCards } from "./api/userApi";
Amplify.configure(awsconfig);

function Main(props) {
  const allTabs = [
    "/login",
    "/signup",
    "/password",
    "/revise",
    "/saved",
    "/settings",
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const darkMode = props.darkMode;
  const setDarkMode = props.setDarkMode;
  const [isLanding, setIsLanding] = useState(true);
  const [user, setUser] = useState(null);
  const [isUserLoggedIn, setUserLoggedIn] = useState("initializing");
  const [open, setOpen] = React.useState(false);
  const [savedCards, setSavedCards] = useState([]);
  const [loadingCards, setLoadingCards] = useState(true);

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
    },
    navbar: {
      background: isLanding ? "transparent" : theme.palette.secondary.dark,
      boxShadow: "none",
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingLeft: "100px",
      paddingRight: "100px",
      zIndex: 100,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: "50px",
        paddingRight: "50px",
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 25,
        paddingRight: 25,
      },
    },
    toolbar: {
      padding: 0,
    },
    nav: {
      position: "relative",
      width: "100%",
    },
    logo: {
      height: 60,
      width: 60,
      marginTop: 10,
      marginLeft: 10,
    },
    hide: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      sidebar: {
        display: "none",
      },
    },
  }));
  
  const StyledTabs = withStyles((theme) => ({
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        maxWidth: 85,
        width: "100%",
      },
      height: "4px",
    },
    root: {
      float: "right",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  }))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
  
  const StyledTab = withStyles((theme) => ({
    root: {
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: "16px",
      color: theme.color,
      transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      opacity: 1,
      "&:hover": {
        textDecoration: "none",
        color: "#21CE99",
      },
      "&.Mui-selected": {
        color: theme.palette.primary.main,
      },
    },
  }))((props) => <Tab disableRipple {...props} />);
  
  const StyledButtonTab = withStyles((theme) => ({
    root: theme.button
  }))((props) => <Tab disableRipple {...props} />);

  const classes = useStyles();

  useEffect(() => {
    const handleUser = async (user) => {
      setUser(user);

      if (isUserLoggedIn === "loggedIn") {
        let result = await getSavedCards(user.attributes.email);
        setSavedCards(result);
        setIsLanding(false);
      } else {
        setDarkMode(true);
        setIsLanding(true);
      }
    };

    switch (isUserLoggedIn) {
      case "loggedIn":
        getUser().then((userData) => handleUser(userData));
        break;
      case "loggedOut":
        handleUser(null);
        break;
      default:
        break;
    }

    
    setLoadingCards(false);
  }, [isUserLoggedIn]);

  useEffect(() => {
    checkAuthState();
  }, []);

  async function checkAuthState() {
    try {
      await Auth.currentAuthenticatedUser();
      setUserLoggedIn("loggedIn");
    } catch (err) {
      setUserLoggedIn("loggedOut");
    }
  }

  const updateAuthState = (status) => {
    setUserLoggedIn(status);
  };

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget.id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchQuestions());
  }, [dispatch]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  window.onkeydown = function (e) {
    if (document.URL.includes("revise")) {
      return e.keyCode !== 32;
    }
  };

  return (
    <React.StrictMode>
        <Paper elevation={0} square>
          <BrowserRouter>
            <div className={classes.root}>
              <Sidebar
                animation="bubble"
                right
                StyleTabs={StyledTabs}
                StyleTab={StyledTab}
                StyleButtonTab={StyledButtonTab}
                setDarkMode={handleDarkMode}
                anchorEl={anchorEl}
                open={open}
                darkMode={darkMode}
                handleDrawerClose={handleDrawerClose}
                updateAuthState={updateAuthState}
                isUserLoggedIn={isUserLoggedIn}
              />
              <Route
                path="/"
                render={({ location }) => (
                  <Fragment>
                    <AppBar
                      position="relative"
                      className={classes.navbar}
                    >
                      <Toolbar
                        className={classes.toolbar}
                      >
                        <Logo />
                        {isUserLoggedIn !== "loggedOut" ? (
                          <div
                            className={classes.nav}
                          >
                            <IconButton
                              style={{
                                float: "right",
                              }}
                              color="inherit"
                              aria-label="open drawer"
                              edge="end"
                              onClick={handleDrawerOpen}
                              className={clsx(
                                open ? classes.hide : classes.sidebar
                              )}
                            >
                              <MenuIcon />
                            </IconButton>
                            <StyledTabs
                              value={location.pathname}
                              aria-label="styled tabs example"
                            >
                              <StyledTab
                                label="Home"
                                value={"/"}
                                component={Link}
                                to={"/"}
                                className={classes.hide}
                              />
                              <StyledTab
                                label="Practice"
                                value={allTabs[3]}
                                component={Link}
                                to={allTabs[3]}
                              />
                              <StyledTab
                                label="Saved"
                                value={allTabs[4]}
                                component={Link}
                                to={allTabs[4]}
                              />
                              <StyledTab
                                label="My Account"
                                id="account-tab"
                                value={allTabs[5]}
                                onClick={handleClick}
                                style={{ paddingLeft: 25 }}
                              />
                              <AccountMenu
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                setDarkMode={setDarkMode}
                                darkMode={darkMode}
                                updateAuthState={updateAuthState}
                              />
                            </StyledTabs>
                          </div>
                        ) : (
                          <div
                            className={classes.nav}
                          >
                            <IconButton
                              style={{
                                float: "right",
                              }}
                              color="inherit"
                              aria-label="open drawer"
                              edge="end"
                              onClick={handleDrawerOpen}
                              className={clsx(
                                open ? classes.hide : classes.sidebar
                              )}
                            >
                              <MenuIcon />
                            </IconButton>
                            <StyledTabs
                              value={location.pathname}
                              aria-label="styled tabs example"
                            >
                              <StyledTab
                                label="Login"
                                value={allTabs[0]}
                                component={Link}
                                to={allTabs[0]}
                              />
                              <StyledButtonTab
                                label="Sign Up"
                                value={allTabs[1]}
                                component={Link}
                                to={allTabs[1]}
                              />
                            </StyledTabs>
                          </div>
                        )}
                      </Toolbar>
                    </AppBar>
                    <div>
                      <Switch>
                        <Route
                          path={allTabs[0]}
                          component={() => (
                            <Login
                              updateAuthState={updateAuthState}
                              isUserLoggedIn={isUserLoggedIn}
                            />
                          )}
                        />
                        <Route
                          path={allTabs[1]}
                          render={() => (
                            <SignUp isUserLoggedIn={isUserLoggedIn} />
                          )}
                        />
                        <Route
                            path={allTabs[2]}
                            render={() => (
                              <Password isUserLoggedIn={isUserLoggedIn} />
                            )}
                          />
                        <Route
                          path={allTabs[3]}
                          render={() => (
                            <Revise
                              isUserLoggedIn={isUserLoggedIn}
                              user={user}
                              savedCards={savedCards}
                              setSavedCards={setSavedCards}
                              loadingCards={loadingCards}
                            />
                          )}
                        />
                        <Route
                          path={allTabs[4]}
                          render={() => (
                            <Saved
                              isUserLoggedIn={isUserLoggedIn}
                              savedCards={savedCards}
                              setSavedCards={setSavedCards}
                              user={user}
                            />
                          )}
                        />
                        <Route
                          path={allTabs[5]}
                          render={() => (
                            <Settings isUserLoggedIn={isUserLoggedIn} />
                          )}
                        />
                        <Route
                          exact
                          path={"/"}
                          render={() => (
                            isUserLoggedIn !== "loggedOut" ? 
                            <Home
                              name={user && user.attributes.email}
                            /> :
                            <Landing />
                          )}
                        />
                        <Route
                          render={() => <Redirect to={{ pathname: "/" }} />}
                        />
                      </Switch>
                    </div>
                  </Fragment>
                )}
              />
            </div>
          </BrowserRouter>
        </Paper>
    </React.StrictMode>
  );
}

export default Main;
