import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
  },
  container: {
    paddingTop: "50px",
    paddingBottom: "50px",
    paddingLeft: "100px",
    paddingRight: "100px",
    [theme.breakpoints.down('sm')]: {
      paddingLeft: "50px",
      paddingRight: "50px",
    },
    [theme.breakpoints.down('xs')]: {
      padding: 25,
    },
  },
  formContainer: {
    background: theme.palette.secondary.light,
    borderRadius: theme.borderRadius,
    boxShadow: theme.boxShadow,
    padding: "50px",
    minWidth: "50%",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      padding: "25px",
    },
    zIndex: "100",
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    zIndex: "100",
    marginBottom: "25px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.5rem",
    },
  },
  label: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.secondary.contrastText,
  },
  textBox: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
    paddingLeft: "5px",
  },
  updateButton: theme.button,
  [theme.breakpoints.down("md")]: {
    header: {
      fontSize: "24px",
    },
    subHeader: {
      fontSize: "20px",
    },
    content: {
      width: "90vw",
    },
    label: {
      fontSize: "16px",
    },
    update: {
      fontSize: "16px",
    },
  },
}));

function Settings(props) {
  const classes = useStyles();
  let history = useHistory();
  const [confirmPasswordError, setPasswordConfirmError] = useState(false);
  const [passwordFormatError, setPasswordFormatError] = useState(false);
  const [wrongPasswordError, setWrongPasswordError] = useState(false);
  const { register, handleSubmit, reset } = useForm();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    let newPassword = data.newPassword;
    let confirmNewPassword = data.confirmNewPassword;

    const pe = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d).{8,128}$/;
    if (pe.test(newPassword)) {
      setPasswordFormatError(false);
    } else {
      setPasswordFormatError(true);
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setPasswordConfirmError(true);
      return;
    } else {
      setPasswordConfirmError(false);
    }

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, data.currentPassword, newPassword);
      setWrongPasswordError(false);
      reset({});
      handleClickOpen();
    } catch (error) {
      setWrongPasswordError(true);
    }
  };

  if (props.isUserLoggedIn === "loggedOut") {
    history.push("/login");
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid
          container
          direction={"column"}
          alignItems={"center"}
        >
            <Grid item>
              <Typography className={classes.title} variant={"h4"}>
                Account settings
              </Typography>
            </Grid>
            <Grid 
            item 
            container
            direction={"row"}
            justify={"center"}
            >
              <form
                className={classes.formContainer}
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid item>
                  <Typography className={classes.label}>
                    Current Password
                  </Typography>
                  <TextField
                    style={{width: "100%",}}
                    inputProps={ classes.textBox }
                    required
                    error={wrongPasswordError}
                    helperText={wrongPasswordError && "Wrong current password!"}
                    inputRef={register}
                    type={"password"}
                    id="currentPassword"
                    name="currentPassword"
                  />
                </Grid>
                <br />
                <Grid item>
                  <Typography className={classes.label}>New Password</Typography>
                  <TextField
                    style={{width: "100%",}}
                    inputProps={ classes.textBox }
                    required
                    error={passwordFormatError}
                    helperText={
                      passwordFormatError &&
                      "passwords needs to be longer than 8, and contains at least one large case, one lower case, and one numerical digit"
                    }
                    inputRef={register}
                    type={"password"}
                    id="newPassword"
                    name="newPassword"
                  />
                </Grid>
                <br />
                <Grid item>
                  <Typography className={classes.label}>
                    Confirm New Password
                  </Typography>
                  <TextField
                    style={{width: "100%",}}
                    inputProps={ classes.textBox }
                    required
                    error={confirmPasswordError}
                    helperText={confirmPasswordError && "passwords do not match"}
                    inputRef={register}
                    type={"password"}
                    id="confirmNewPassword"
                    name="confirmNewPassword"
                  />
                </Grid>
                <br />
                <Grid item>
                  <Button className={classes.updateButton} type={"submit"}>
                    Update Password
                  </Button>
                </Grid>
              </form>
            </Grid>
        </Grid>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your password has been reset successfully!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Settings;
