import React from "react";
import { Grid, Hidden, Typography, Button, Dialog } from "@material-ui/core";
import FilterBox from "../components/FilterBox";
import Flashcard from "../components/Flashcard";
import HotkeyBox from "../components/HotkeyBox";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "calc(100vh - 80px)",
    backgroundColor: theme.palette.background.default,
  },
  container: {
    paddingTop: "50px",
    paddingBottom: "50px",
    paddingLeft: "100px",
    paddingRight: "100px",
    [theme.breakpoints.down('sm')]: {
      paddingLeft: "50px",
      paddingRight: "50px",
    },
    [theme.breakpoints.down('xs')]: {
      height: "calc(100%)",
      padding: 25,
    },
  },
  showFiltersBar: {
    marginBottom: 10,
    marginTop: -10,
  },
  filterBox: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  }
}));

function Revise(props) {
  const classes = useStyles();
  let history = useHistory();

  const filterParam = history.location.search.split("=")[1];

  if (props.isUserLoggedIn === "loggedOut") {
    history.push("/login");
  }

  const [open, setOpen] = React.useState(false);
  const showFilters = () => {
    setOpen(true);
  };
  const closeFilters = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Hidden smUp>
          <Grid
            container
            item
            className={classes.showFiltersBar}
            direction={"column"}
            alignItems={"flex-end"}
          >
            <Button 
            className={classes.save}
            className={classes.icon}
            disableRipple
            onClick={showFilters}>
              <Typography
                className={classes.subheading}
                style={{ marginRight: "5px", }}
              >
                Filter
              </Typography>
              <FilterListIcon />
            </Button>
            <Dialog open={open} onClose={closeFilters} aria-labelledby="form-dialog-title">
              <Grid item className={classes.filterBox}>
                <FilterBox filterParam={filterParam} mobileView={true} onClose={closeFilters} />
              </Grid>
            </Dialog>
          </Grid>
        </Hidden>
        <Grid
          container
          spacing={5}
        >
          <Hidden xsDown>
            <Grid item className={classes.filterBox}>
              <FilterBox filterParam={filterParam} />
            </Grid>
          </Hidden>
          <Grid
            container
            item
            direction={"column"}
            alignItems={"center"}
            style={{ flex: 1 }}
          >
            <Grid item style={{ width: "100%" }}>
              <Flashcard
                user={props.user}
                savedCards={props.savedCards}
                setSavedCards={props.setSavedCards}
              />
            </Grid>
            <Hidden xsDown>
              <Grid item>
                <HotkeyBox />
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Revise;
