import React, { useEffect, useState, useCallback } from "react";
import { Container, Typography, Button, Grid, Hidden, IconButton, CircularProgress } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { BookmarkBorder, ArrowForward, ArrowBack } from "@material-ui/icons";
import SavedIcon from "../icons/SavedIcon";
import Tag from "./Tag";

import { getDisplayedFlashcards } from "../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentIndex } from "../redux/actions";

import { useHotkeys } from "react-hotkeys-hook";

import { postBookmark, deleteBookmark } from "../api/userApi";

const useStyles = makeStyles((theme) => ({
  page: {
    color: theme.palette.type === "dark" ? "#fff" : "#818181",
    fontSize: "1.25rem",
    display: "inline-block",
    lineHeight: "1.5rem",
    marginTop: "auto",
    marginBottom: "auto",
    whiteSpace: "nowrap",
  },
  tagContainer: {
    maxWidth: "calc(100% - 72px)",
  },
  save: {
    alignItems: "flex-start",
    padding: 0,
    "&:hover": {
      background: "none",
      borderWidth: "3px",
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  subheading: {
    fontWeight: "bold",
    fontSize: "1.25rem",
    textTransform: "uppercase",
    display: "inline-block",
  },
  flashcardBackground: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.borderRadius,
    boxShadow: theme.boxShadow,
    textAlign: "center",
    padding: "30px",
    position: "relative",
    display: "flex",
    flexFlow: "column",
  },

  questionContainer: {
    borderRadius: theme.borderRadius,
    textAlign: "left",
    padding: "15px 0px",
    marginBottom: "10px",
    width: "100%",
    display: "flex",
  },

  answerContainer: {
    background: theme.palette.type === "dark" ? "#565656" : "#fff",
    borderRadius: theme.borderRadius,
    textAlign: "left",
    position: "relative",
    padding: "15px 25px 15px 25px",
    width: "100%",
    display: "flex",
  },

  questionContent: {
    fontWeight: "bold",
    fontSize: "1.25rem",
    lineHeight: "1.5",
    display: "inline-block",
  },
  answerContent: {
    fontSize: "1.25rem",
  },
  showButton: {
    borderRadius: theme.borderRadius,
    color: "white",
    textAlign: "center",
    textTransform: "uppercase",
    padding: "10px 20px",
    boxShadow: "none",

    position: "absolute",
    left: "50%",
    top: "50%",
    webkitTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",

    maxWidth: "100%",

    "&:hover": {
      backgroundColor: "#ffffff",
      color: theme.palette.primary.main,
      boxShadow: "none",
    },
  },
  hideButton: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: theme.borderRadius,
    color: theme.palette.type === "dark" ? "#B4B4B4" : "#818181",
    textAlign: "center",
    textTransform: "uppercase",
    padding: "10px 20px",
    boxShadow: "none",
    marginTop: "20px",
    maxWidth: "100%",

    "&:hover": {
      backgroundColor: "#B1B1B1",
      color: "white",
      boxShadow: "none",
    },
  },
  controlsButtonIcon: {
    fontSize: 32,
  },
  leftButton: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.light,

    padding: "5px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  rightButton: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.light,

    padding: "5px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  controlsBar: {
    marginTop: 25,
    marginBottom: 25,
    flexWrap: "wrap-reverse",
  },
  [theme.breakpoints.down("xs")]: {
    pageContainer: {
      position: "absolute",
      top: -40,
    },
    flashcardBackground: {
      padding: 20,
    },
    questionContainer: {
      maxHeight: "15vh",
      padding: "5px 0",
      overflowY: "scroll",
    },
    questionContent: {
      fontSize: "1rem",
    },
    answerContainer: {
      maxHeight: "30vh",
      overflowY: "scroll",
    },
    answerContent: {
      fontSize: "1rem",
    },
    subheading: {
      fontSize: "1rem",
    },
    page: {
      fontSize: "1rem",
    },
    controlsBar: {
      position: "absolute",
      bottom: 0,
      width: "calc(100% - 50px)",
    },
    showButton: {
      top: 0,
      left: 0,
      position: "relative",
      transform: "none",
    },
    hideButton: {
      top: 0,
      left: 0,
      position: "relative",
      transform: "none",
      marginTop: 0,
    },
    tagContainer: {
      maxWidth: "calc(100% - 25px)",
    },
    save: {
      minWidth: "auto",
    },
  },
}));

function Flashcard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const displayedFlashcards = useSelector(getDisplayedFlashcards);
  const currentIndex = useSelector((state) => state.currentIndex);
  const [currentFlashcard, setCurrentFlashcard] = useState(
    displayedFlashcards[currentIndex]
  );
  const [loading, setLoading] = useState(false);
  const { savedCards, setSavedCards, user } = props;

  const handleSave = async () => {
    try {
      if (saved === false) {
        let param = {
          emailAddress: user.attributes.email,
          flashCardID: currentFlashcard.id,
          flashCard: currentFlashcard,
        };
        setLoading(true);
        await postBookmark(param);
        savedCards.push(param);
        setSavedCards(savedCards);
        setLoading(false);
        setSaved(true);
      } else {
        let param = {
          emailAddress: props.user.attributes.email,
          flashCardID: currentFlashcard.id,
        };
        setLoading(true);
        await deleteBookmark(param);
        let result = savedCards.filter(
          (item) => item.flashCardID !== currentFlashcard.id
        );
        setSavedCards(result);
        setLoading(false);
        setSaved(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const previousFlashcard = useCallback(() => {
    dispatch(updateCurrentIndex(currentIndex - 1));
    setShowAnswer(false);
  }, [dispatch, currentIndex]);

  const nextFlashcard = useCallback(() => {
    dispatch(updateCurrentIndex(currentIndex + 1));
    setShowAnswer(false);
  }, [dispatch, currentIndex]);

  useEffect(() => {
    setCurrentFlashcard(displayedFlashcards[currentIndex]);
  }, [displayedFlashcards, currentIndex]);

  const [show, setShowAnswer] = useState(false);
  const [saved, setSaved] = useState(false);
  const [move, setMove] = useState("");

  useEffect(() => {
    if (
      savedCards &&
      currentFlashcard &&
      savedCards.find((item) => item.flashCardID === currentFlashcard.id)
    ) {
      setSaved(true);
    } else {
      setSaved(false);
    }
  }, [currentFlashcard, savedCards]);

  const AnswerContent = withStyles({
    root: {
      visibility: show ? "visible" : "hidden",
      opacity: show ? "1" : "0",
      transition: "visibility 0s, opacity 0.5s linear",
    },
  })(Typography);

  useHotkeys("s", handleSave, [user, currentFlashcard, savedCards, saved]);
  useHotkeys("left", () => setMove("left"), [move]);
  useHotkeys("right", () => setMove("right"), [move]);
  useHotkeys("space", () => setShowAnswer(!show), [show]);

  useEffect(() => {
    if (move === "left") {
      previousFlashcard();
    }

    if (move === "right") {
      nextFlashcard();
    }

    setMove("");
  }, [move, nextFlashcard, previousFlashcard]);

  return (
    <div id="top">
      {currentFlashcard === undefined ? (
        <Grid container justify="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <div className={classes.flashcardContainer}>
          <Grid container justify="center" alignItems="center" className={classes.flashcardBackground}>
            <React.Fragment>
              <Hidden smUp>
                <Grid item className={classes.pageContainer}>
                  <Typography id="flashcard-id" className={classes.page}>
                    {currentIndex + 1} / {displayedFlashcards.length}
                  </Typography>
                </Grid>
              </Hidden>
              <Grid container justify="space-between">
                <Grid item container justify="flex-start" className={classes.tagContainer}>
                  <Tag text={currentFlashcard.topic} />
                  <Tag text={currentFlashcard.difficulty} />
                </Grid>
                <Grid item justify="flex-end">
                  <Button className={classes.save} disableRipple onClick={handleSave}>
                    <Hidden xsDown>
                      <Typography style={{ marginRight: "5px", }}>
                        Save
                      </Typography>
                    </Hidden>
                    {loading ? (
                      <CircularProgress size={"1.25rem"} />
                    ) : (
                      saved ? (
                        <SavedIcon />
                      ) : (
                        <BookmarkBorder />
                      )
                    )}
                  </Button>
                </Grid>
              </Grid>

              <Container className={classes.questionContainer}>
                <Typography
                  id="question-content"
                  className={classes.questionContent} variant={"h4"}
                >
                  {currentFlashcard.question}
                </Typography>
              </Container>

              <Container
                id="answer-container"
                className={classes.answerContainer}
                style={{
                  flex: show ? "1" : "none",
                  height: show ? "100%" : "30vh",
                }}
              >
                {show && (
                    <AnswerContent id="answer-content" className={classes.answerContent} component={"p"}>
                      {currentFlashcard.answer}
                    </AnswerContent>
                  )}
                <Hidden xsDown>
                  {!show && (
                    <Button
                      id="show-button"
                      className={classes.showButton}
                      color="primary"
                      variant={"contained"}
                      onClick={() => setShowAnswer(!show)}
                    >
                      Show Answer
                    </Button>
                  )}
                </Hidden>
              </Container>

              <Hidden xsDown>
              {show && (
                <Button
                  id="show-button"
                  href="#top"
                  className={classes.hideButton}
                  color="primary"
                  variant={"contained"}
                  onClick={() => setShowAnswer(!show)}
                >
                  Hide Answer
                </Button>
              )}
              </Hidden>
            </React.Fragment>
          </Grid>
          <Grid container justify="space-between" alignItems="center" className={classes.controlsBar}>
            <IconButton
              className={classes.leftButton}
              onClick={previousFlashcard}
            >
              <ArrowBack className={classes.controlsButtonIcon} />
            </IconButton>
            <Hidden xsDown>
              <Grid item justify="center" className={classes.pageContainer}>
                <Typography id="flashcard-id" className={classes.page}>
                  {currentIndex + 1} / {displayedFlashcards.length}
                </Typography>
              </Grid>
            </Hidden>
            <Hidden smUp>
              {!show && (
                <Button
                  id="show-button"
                  className={classes.showButton}
                  color="primary"
                  variant={"contained"}
                  onClick={() => setShowAnswer(!show)}
                >
                  Show Answer
                </Button>
              )}
              {show && (
                <Button
                  id="show-button"
                  href="#top"
                  className={classes.hideButton}
                  color="primary"
                  variant={"contained"}
                  onClick={() => setShowAnswer(!show)}
                >
                  Hide Answer
                </Button>
              )}
            </Hidden>
            <IconButton
              className={classes.rightButton}
              onClick={nextFlashcard}
            >
              <ArrowForward className={classes.controlsButtonIcon} />
            </IconButton>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default Flashcard;
