import React from "react";
import { Instagram, Facebook, LinkedIn, Email } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Typography } from "@material-ui/core";
import clsx from "clsx";
import Logo from "../icons/Chancery_logo.png";

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
  },
  infoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: "50px",
    paddingBottom: "50px",
  },
  brandContainer: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logoContainer: {
    textAlign: "center",
  },
  logo: {
    width: "50px",
  },
  logoName: {
    fontWeight: "bold",
  },
  brandSlogan: {
    color: theme.palette.secondary.contrastText,
    fontWeight: "bold",
  },
  socialLinks: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
    marginBottom: "10px",
  },
  copyrightText: {
    color: "grey",
  },
  aboutColumn: {
    display: "flex",
    flexDirection: "column",
    width: "25%",
  },
  columnHeading: {
    fontWeight: "bold",
  },
  pageLink: {
    color: "grey",
    marginBottom: "5px",
    transition: "all 0.2s",

    "&:hover": {
      color: theme.palette.secondary.contrastText,
      textDecoration: "none",
    }
  },
  icon: {
    fontSize: "18px",
    transition: "all 0.2s",
    marginRight: "10px",
    color: "white",
    borderRadius: "100%",
    backgroundColor: "grey",
    padding: "5px",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
}));

function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.infoContainer}>
        <div className={classes.brandContainer}>
          <div className={classes.logoContainer}>
            <img src={Logo} className={classes.logo} alt="Logo" />
            <Typography className={classes.logoName} variant={"h4"} color={"primary"}>
              Chancery
            </Typography>
          </div>
          <Typography className={classes.brandSlogan} variant={"h6"}>Launch your career.</Typography>
          <div className={classes.socialLinks}>
            <Link href="https://www.instagram.com/chancery.app/" target={"__blank"}>
              <Instagram className={clsx(classes.icon, classes.instagramIcon)} />
            </Link>
            <Link
              href="https://www.facebook.com/Chancery-109175661220313"
              target={"__blank"}
            >
              <Facebook className={classes.icon} />
            </Link>
            <Link
              href="https://www.linkedin.com/company/chanceryapp/about/"
              target={"__blank"}
            >
              <LinkedIn className={classes.icon} />
            </Link>
            <Link href="mailto: support@chancery.app" target={"__blank"}>
              <Email className={clsx(classes.icon, classes.emailIcon)} />
            </Link>
          </div>
          <Typography className={classes.copyrightText}>© 2021 Chancery</Typography>
        </div>
      </div>
    </div>
  );
}

export default Footer;
