import React, { useState } from "react";
import { Container, Typography, Grid, CircularProgress, Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SavedIcon from "../icons/SavedIcon";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import { deleteBookmark } from "../api/userApi";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "25px",
  },
  title: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  subheading: {
    fontWeight: "bold",
    fontSize: "1.25rem",
    textTransform: "uppercase",
    display: "inline-block",
  },
  indexContainer: {
    padding: "30px",
  },
  flashcardBackground: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.borderRadius,
    boxShadow: theme.boxShadow,
    textAlign: "center",
    padding: "30px",
    position: "relative",
  },
  questionContainer: {
    padding: "0 10px 10px 0",
    display: "flex",
  },
  questionContent: {
    fontWeight: "bold",
    fontSize: "1.25rem",
    display: "inline-block",
    textAlign: "left",
  },
  save: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  saveIcon: {
    fontSize: "3rem",
    position: "absolute",
  },
  answerContainer: {
    padding: "0 10px 0 0",
    display: "flex",
  },
  answerContent: {
    fontSize: "1.25rem",
    display: "inline-block",
    textAlign: "left",
  },
  showButton: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: theme.borderRadius,
    color: theme.palette.type === "dark" ? "#B4B4B4" : "#818181",
    textAlign: "center",
    textTransform: "uppercase",
    padding: "10px 20px",
    boxShadow: "none",
    marginTop: "20px",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "100%",

    "&:hover": {
      backgroundColor: "#B1B1B1",
      color: "white",
      boxShadow: "none",
    },
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      marginRight: 0,
    },
    subheading: {
      fontSize: "1rem",
    },
    indexContainer: {
      padding: "15px",
    },
    flashcardBackground: {
      padding: "15px",
    },
    questionContent: {
      fontSize: "1rem",
    },
    saveIcon: {
      fontSize: "2rem",
    },
    answerContent: {
      fontSize: "1rem",
    }
  },
}));

function SavedFlashcard(props) {
  const classes = useStyles();

  const [show, setShowAnswer] = useState(false);
  const [saved, setSaved] = useState(true);
  const [loading, setLoading] = useState(false);

  const { index, user, cardInfo, setSavedCards, savedCards } = props;

  const handleSaved = async () => {
    let param = {
      emailAddress: user.attributes.email,
      flashCardID: cardInfo.id,
    };
    setLoading(true);
    await deleteBookmark(param);
    let result = savedCards.filter((item) => item.flashCardID !== cardInfo.id);
    setSavedCards(result);
    setSaved(false);
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item container xs={12} md={12} className={classes.flashcardBackground}>
          <Grid item container xs={11}>
            <Container
              className={classes.questionContainer}
              style={{ display: "flex" }}
            >
              <Typography className={classes.subheading}>Q.&emsp;</Typography>

              <Typography
                id="question-content"
                className={classes.questionContent}
              >
                {cardInfo.question}
              </Typography>
            </Container>

            <Container
              id="answer-container"
              className={classes.answerContainer}
            >
              {show && (
                <Typography
                  id="answer-initial"
                  className={classes.subheading}
                  color="primary"
                >
                  A.&emsp;
                </Typography>
              )}
              {show && (
                <Typography
                  id="answer-content"
                  className={classes.answerContent}
                >
                  {cardInfo.answer}
                </Typography>
              )}
            </Container>
          </Grid>

          <Grid item container xs={1} justify="center">
            <IconButton
              className={classes.save}
              disableRipple
              onClick={handleSaved}
            >
              {loading ? (
                <CircularProgress
                  style={{ 
                    top: "7px", 
                  }}
                  className={classes.saveIcon}
                />
              ) : saved ? (
                <SavedIcon
                  style={{
                    top: "0px",
                  }}
                  className={classes.saveIcon}
                />
              ) : (
                <BookmarkBorderIcon
                  style={{
                    top: "0px",
                  }}
                  className={classes.saveIcon}
                />
              )}
            </IconButton>
          </Grid>

          {show ? (
            <Button
              id="show-button"
              className={classes.showButton}
              color="primary"
              variant={"contained"}
              onClick={() => setShowAnswer(!show)}
            >
              {" "}
              Hide Answer
            </Button>
          ) : (
            <Button
              id="show-button"
              className={classes.showButton}
              color="primary"
              variant={"contained"}
              onClick={() => setShowAnswer(!show)}
            >
              Show Answer
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default SavedFlashcard;
