import React from "react";
import { Typography, Grid} from "@material-ui/core";
import Card from "../components/Card";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
  },
  container: {
      paddingTop: "50px",
      paddingBottom: "50px",
      paddingLeft: "100px",
      paddingRight: "100px",
      [theme.breakpoints.down('sm')]: {
        paddingLeft: "50px",
        paddingRight: "50px",
      },
      [theme.breakpoints.down('xs')]: {
        padding: 25,
      },
  },
  listTitle: {
      fontWeight: "bold",
      marginTop: "5px",
      marginBottom: "5px",
      color: theme.palette.secondary.contrastText,
  },
  list: {
      paddingTop: "10px",
      paddingBottom: "10px",
  },
}));

function Home(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography className={classes.listTitle} variant={"h6"}>
          Recommended Sets
        </Typography>
        <Grid
          className={classes.list}
          item
          container
          direction="row"
          justify="flex-start"
        >
          <Card cardTitle={"Accounting"} cardNumCards={"117"} />
          <Card cardTitle={"EV / Equity Value"} cardNumCards={"43"} />
          <Card cardTitle={"Valuation"} cardNumCards={"73"} />
          <Card cardTitle={"Discounted Cash Flow"} cardNumCards={"66"} />
          <Card cardTitle={"Merger Model"} cardNumCards={"92"} />
          <Card cardTitle={"LBO"} cardNumCards={"77"} />
        </Grid>
      </div>
    </div>
  );
}

export default Home;
