import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography, Grid, Hidden,  } from "@material-ui/core";
import Footer from "../components/Footer";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Graphic from "../icons/landing_graphic.svg";
import Blob from "../icons/blob.png";

import Screenshot1 from "../icons/screenshot1.png";
import Screenshot2 from "../icons/screenshot2.png";

import Company1 from "../icons/company/company1.png";
import Company2 from "../icons/company/company2.png";
import Company3 from "../icons/company/company3.png";
import Company4 from "../icons/company/company4.png";
import Company5 from "../icons/company/company5.png";
import Company6 from "../icons/company/company6.png";
import Company7 from "../icons/company/company7.png";
import Company8 from "../icons/company/company8.png";
import Company9 from "../icons/company/company9.png";
import Company10 from "../icons/company/company10.png";
import Company11 from "../icons/company/company11.png";
import Company12 from "../icons/company/company12.png";

const useStyles = makeStyles(theme => ({
  landingPage: {
    background: "white",
  },
  landingPageBackground: {
    position: "absolute",
    width: "100%",
    height: "100%",
    transform: "skewY(-10deg)",
    transformOrigin: "0%",
    background: "linear-gradient(-150deg, #212121 15%, #313131 55%, #3c5958 95%)",
  },
  landingBanner: {
    position: "relative",
    paddingLeft: "100px",
    paddingRight: "100px",
    paddingTop: "50px",
    paddingBottom: "150px",
  },
  landingBannerColumn: {
    width: "50%",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  title: {
    fontSize: "40px",
    fontWeight: "bold",
    marginBottom: "10px",
    marginTop: "10px",
    position: "relative",
  },
  heading: {
    fontSize: "32px",
    fontWeight: "bold",
    position: "relative",
    marginBottom: "5px",
  },
  subheading: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "20px",
    opacity: "0.5",
    position: "relative",
  },
  graphic: {
    maxWidth: "100%",
    maxHeight: "55vh",
    zIndex: "1",
  },
  button: theme.button,
  link: theme.link,
  companiesBanner: {
    background: "white",
    paddingLeft: "100px",
    paddingRight: "100px",
    paddingBottom: "50px",
    color: "#313131",
  },
  companiesHeading: {
    marginBottom: "25px",
    textAlign: "center",
  },
  companiesContainer: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  companyLogo: {
    width: "100px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  featuresBanner: {
    paddingTop: "50px",
    paddingBottom: "50px",
    paddingLeft: "100px",
    paddingRight: "100px",
    background: "#313131",
  },
  featuresHeading: {
    textAlign: "center",
  },
  featuresContainer: {
    paddingTop: "50px",
    paddingBottom: "50px",
    flexWrap: "wrap",
  },
  featuresDescription: {
    width: "33%",
  },
  featuresItem: {
    marginBottom: "25px",
    paddingLeft: "25px",
    paddingRight: "25px",
    borderLeftWidth: "5px",
    borderLeftStyle: "solid",
  },
  featuresItemTitle: {
    fontWeight: "bold",
  },
  featuresGraphicContainer: {
    width: "66%",
  },
  featuresGraphic: {
    maxWidth: "100%",
    maxHeight: "70vh",
    zIndex: "1",
  },
  mobileBanner: {
    paddingTop: "50px",
    paddingBottom: "50px",
    paddingLeft: "100px",
    paddingRight: "100px",
    color: "#313131",
  },
  mobileColumn: {
    position: "relative",
    width: "33%",
    textAlign: "center",
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    marginBottom: "25px",
  },
  mobileDescription: {
    zIndex: "5",
  },
  mobileBlob: {
    position: "absolute",
    maxHeight: "65vh",
    maxWidth: "100%",
  },
  mobileScreenshot: {
    zIndex: "2",
    maxHeight: "65vh",
    maxWidth: "50%",
  },
  [theme.breakpoints.down('sm')]: {
    landingBanner: {
      paddingLeft: "50px",
      paddingRight: "50px",
    },
    title: {
      fontSize: "32px",
    },
    heading: {
      fontSize: "28px",
    },
    subheading: {
      fontSize: "16px",
    },
    companiesBanner: {
      paddingLeft: "50px",
      paddingRight: "50px",
    },
    featuresBanner: {
      paddingLeft: "50px",
      paddingRight: "50px",
    },
    featuresDescription: {
      width: "100%",
    },
    featuresItem: {
      width: "25%",
      marginBottom: "15px",
      paddingLeft: "15px",
      paddingRight: "15px",
    },
    featuresItemTitle: {
      fontSize: "22px",
    },
    featuresGraphicContainer: {
      width: "100%",
    },
    mobileBanner: {
      paddingLeft: "50px",
      paddingRight: "50px",
    },
    mobileColumn: {
      width: "50%",
    },
  },
  [theme.breakpoints.down('xs')]: {
    landingBannerColumn: {
      width: 'auto',
    },
    featuresItem: {
      width: "100%",
    },
    mobileColumn: {
      width: "100%",
    },
  },
}));

function Home(props) {
  const classes = useStyles();
  const theme = useTheme();
  
  return (
    <div className={classes.landingPage}>
      <Grid
        className={classes.landingBanner}
        container
        direction="row"
        alignItems="center"
        justify="center"
        wrap="wrap"
      >
        <div className={classes.landingPageBackground}></div>
        <Grid
        className={classes.landingBannerColumn}
        container
        direction="column"
        alignItems="flex-start"
        justify="center"
        >
          <Typography className={classes.title} variant={"h3"}>
            Prepare for your next investment banking interview
          </Typography>
          <Typography className={classes.subheading} variant={"h5"}>
          Chancery is the best platform to help you learn key finance concepts and practice for technical interviews.
          </Typography>
          <Link
            to={"/signup"}
            style={{ textDecoration: "none" }}
          >
            <Button
              variant={"outlined"}
              className={classes.button}
              color={"primary"}
            >
              Get Started
            </Button>
          </Link>
          </Grid>
          <Grid
          className={classes.landingBannerColumn}
          container
          direction="column"
          alignItems="center"
          justify="center"
          >
            <img src={Graphic} className={classes.graphic} alt="Graphic" />
          </Grid>
      </Grid>
      <div className={classes.companiesBanner}>
        <div className={classes.companiesHeading}>
          <Typography className={classes.heading} variant={"h5"} color={"primary"}>Succeed at top companies</Typography>
          <Typography variant={"p"}>Our mission is to prepare you for any investment banking technical question so you can land your dream job. Our users have landed offers at top banks around the world.</Typography>
        </div>
        <Grid
        className={classes.companiesContainer}
        container
        direction="row"
        alignItems="center"
        justify="center"
        >
          <img src={Company1} className={classes.companyLogo} alt="" />
          <img src={Company2} className={classes.companyLogo} alt="" />
          <img src={Company3} className={classes.companyLogo} alt="" />
          <img src={Company4} className={classes.companyLogo} alt="" />
          <img src={Company5} className={classes.companyLogo} alt="" />
          <img src={Company6} className={classes.companyLogo} alt="" />
          <img src={Company7} className={classes.companyLogo} alt="" />
          <img src={Company8} className={classes.companyLogo} alt="" />
          <img src={Company9} className={classes.companyLogo} alt="" />
          <img src={Company10} className={classes.companyLogo} alt="" />
          <img src={Company11} className={classes.companyLogo} alt="" />
          <img src={Company12} className={classes.companyLogo} alt="" />
        </Grid>
      </div>
      <div className={classes.featuresBanner}>
        <div className={classes.featuresHeading}>
          <Typography className={classes.heading} variant={"h5"}>What we offer</Typography>
          <Typography variant={"p"}>We have a database of <strong>450+ questions</strong> so that you can be confident going into any investment banking interview.</Typography>
        </div>
          <Hidden mdUp>
            <Grid
            className={classes.featuresContainer}
            container
            direction="column"
            alignItems="center"
            justify="center"
            >
              <Grid
              className={classes.featuresDescription}
              container
              direction="row"
              alignItems="flex-start"
              justify="center"
              >
                <div className={classes.featuresItem} style={{borderLeftColor: "#8fccb9"}}>
                  <Typography className={classes.featuresItemTitle} variant={"h5"} style={{color: "#8fccb9"}}>Filter by Topic and Difficulty</Typography>
                  <Typography variant={"p"}>Apply filters based on various combinations of topic and difficulty to tailor your prep</Typography>
                </div>
                <div className={classes.featuresItem} style={{borderLeftColor: "#92cbd1"}}>
                  <Typography className={classes.featuresItemTitle} variant={"h5"} style={{color: "#92cbd1"}}>Save Questions</Typography>
                  <Typography variant={"p"}>Save questions you want to revisit in the future to work on your weaknesses</Typography>
                </div>
                <div className={classes.featuresItem} style={{borderLeftColor: "#cc8fc9"}}>
                  <Typography className={classes.featuresItemTitle} variant={"h5"} style={{color: "#cc8fc9"}}>Hotkey Shortcuts</Typography>
                  <Typography variant={"p"}>Use hotkey shortcuts for quick and easy navigation</Typography>
                </div>
              </Grid>
              <Grid
              className={classes.featuresGraphicContainer}
              container
              direction="column"
              alignItems="center"
              justify="center"
              >
                <img src={Screenshot1} className={classes.featuresGraphic} alt="" />
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid
            className={classes.featuresContainer}
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            >
              <Grid
              className={classes.featuresDescription}
              container
              direction="column"
              alignItems="flex-start"
              justify="center"
              >
                <div className={classes.featuresItem} style={{borderLeftColor: "#8fccb9"}}>
                  <Typography className={classes.featuresItemTitle} variant={"h5"} style={{color: "#8fccb9"}}>Filter by Topic and Difficulty</Typography>
                  <Typography variant={"p"}>Apply filters based on various combinations of topic and difficulty to tailor your prep</Typography>
                </div>
                <div className={classes.featuresItem} style={{borderLeftColor: "#92cbd1"}}>
                  <Typography className={classes.featuresItemTitle} variant={"h5"} style={{color: "#92cbd1"}}>Save Questions</Typography>
                  <Typography variant={"p"}>Save questions you want to revisit in the future to work on your weaknesses</Typography>
                </div>
                <div className={classes.featuresItem} style={{borderLeftColor: "#cc8fc9"}}>
                  <Typography className={classes.featuresItemTitle} variant={"h5"} style={{color: "#cc8fc9"}}>Hotkey Shortcuts</Typography>
                  <Typography variant={"p"}>Use hotkey shortcuts for quick and easy navigation</Typography>
                </div>
              </Grid>
              <Grid
              className={classes.featuresGraphicContainer}
              container
              direction="column"
              alignItems="center"
              justify="center"
              >
                <img src={Screenshot1} className={classes.featuresGraphic} alt="" />
              </Grid>
            </Grid>
          </Hidden>
      </div>
      <div className={classes.mobileBanner}>
        <Hidden mdUp>
          <Grid
          className={classes.mobileContainer}
          container
          direction="row"
          alignItems="center"
          justify="center"
          wrap="wrap"
          >
            <Grid 
            className={classes.mobileColumn}
            container
            direction="column"
            alignItems="center"
            justify="center"
            wrap="wrap"
            >
              <Typography variant={"p"} className={classes.mobileDescription} style={{marginBottom: "5px"}}>We have all the resources you need to be fully prepared for any investment banking interview.</Typography>
              <Typography variant={"p"} style={{marginBottom: "5px"}}>All you have to do is learn the questions and answers.</Typography>
              <Link
                to={"/signup"}
                className={classes.link}
                color={"primary"}
                style={{fontWeight: "bold"}}
              >
                  Start your journey now >
              </Link>
            </Grid>
            <Grid 
            className={classes.mobileColumn}
            container
            direction="column"
            alignItems="center"
            justify="center"
            wrap="wrap"
            >
              <img src={Blob} className={classes.mobileBlob} alt="" />
              <img src={Screenshot2} className={classes.mobileScreenshot} alt="" />
            </Grid>
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid
          className={classes.mobileContainer}
          container
          direction="row"
          alignItems="center"
          justify="center"
          wrap="wrap"
          >
            <Grid 
            className={classes.mobileColumn}
            container
            direction="column"
            alignItems="center"
            justify="center"
            wrap="wrap"
            >
              <Typography variant={"p"} className={classes.mobileDescription}>We have all the resources you need to be fully prepared for any investment banking interview.</Typography>
            </Grid>
            <Grid 
            className={classes.mobileColumn}
            container
            direction="column"
            alignItems="center"
            justify="center"
            wrap="wrap"
            >
              <img src={Blob} className={classes.mobileBlob} alt="" />
              <img src={Screenshot2} className={classes.mobileScreenshot} alt="" />
            </Grid>
            <Grid 
            className={classes.mobileColumn}
            container
            direction="column"
            alignItems="center"
            justify="center"
            wrap="wrap"
            >
              <Typography variant={"p"} style={{marginBottom: "5px"}}>All you have to do is learn the questions and answers.</Typography>
              <Link
                to={"/signup"}
                className={classes.link}
                color={"primary"}
                style={{fontWeight: "bold"}}
              >
                  Start your journey now >
              </Link>
            </Grid>
          </Grid>
        </Hidden>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
