import React from "react";
import SavedFlashcard from "../components/SavedFlashcard";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
  },
  container: {
    paddingTop: "50px",
    paddingBottom: "50px",
    paddingLeft: "100px",
    paddingRight: "100px",
    [theme.breakpoints.down('sm')]: {
      paddingLeft: "50px",
      paddingRight: "50px",
    },
    [theme.breakpoints.down('xs')]: {
      padding: 25,
    },
  },
  numSavedCards: {
    textAlign: "center",
    padding: 10,
    marginBottom: 10,
    fontSize: "1.25rem",
  },
  savedFlashcardContainer: {
    padding: 0,
  },
}));

function Saved(props) {
  const classes = useStyles();
  const { savedCards, setSavedCards, loadingCards } = props;
  let history = useHistory();

  if (props.isUserLoggedIn === "loggedOut") {
    history.push("/login");
  }

  let cardElements = [];

  if (savedCards) {
    savedCards.forEach((item, index) => {
      cardElements.push(
        <Container
          key={item.flashCardID}
          className={classes.savedFlashcardContainer}
        >
          <SavedFlashcard
            savedCards={savedCards}
            setSavedCards={setSavedCards}
            index={index}
            cardInfo={item.flashCard}
            user={props.user}
          />
        </Container>
      );
    });
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {loadingCards ? (
          <CircularProgress
            style={{ position: "absolute", left: "50%", top: "50%" }}
          />
        ) : (
          savedCards.length > 0 ?
          (
            <div>
              <Typography className={classes.numSavedCards}>{savedCards.length} cards saved</Typography>
              {cardElements}
            </div>
          )
          : 
          <Typography variant={"h4"} style={{textAlign: 'center', marginTop: "5rem"}}>
                No card has been saved
          </Typography>
        )}
      </div>
    </div>
  );
}

export default Saved;
