import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Grid, Hidden, Typography, TextField, Dialog, DialogActions, DialogContent, DialogContentText, Button, Link} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Redirect, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { postUserInfo } from "../api/userApi";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    color: theme.palette.secondary.main,
  },
  gradient: {
    position: "absolute",
    width: "100%",
    height: "100vh",
    transform: "skewY(-10deg)",
    transformOrigin: "0%",
    background: "linear-gradient(-150deg, #212121 15%, #313131 55%, #3c5958 95%)",
  },
  container: {
    paddingTop: "50px",
    paddingBottom: "50px",
    paddingLeft: "100px",
    paddingRight: "100px",
    [theme.breakpoints.down('sm')]: {
      paddingLeft: "50px",
      paddingRight: "50px",
    },
    [theme.breakpoints.down('xs')]: {
      padding: 25,
    },
  },
  formContainer: {
    background: theme.palette.background.paper,
    borderRadius: theme.borderRadius,
    boxShadow: theme.boxShadow,
    padding: "50px",
    [theme.breakpoints.down('xs')]: {
      padding: "25px",
      minWidth: "calc(100% - 100px)",
    },
    minWidth: "50%",
    zIndex: "100",
  },
  title: {
    color: "#fff",
    fontWeight: "bold",
    zIndex: "100",
    marginBottom: "25px",
    textAlign: "center",
    [theme.breakpoints.down('xs')]: {
      fontSize: "1.75rem",
    },
  },
  label: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  textBox: {
    backgroundColor: "#e5e5e5",
    color: theme.palette.secondary.main,
    paddingLeft: "5px",
  },
  loginButton: theme.button,
  forgotPassword: {
    color: theme.palette.secondary.light,
    display: "inline-block",
  },
  accountYet: {
    color: theme.palette.secondary.contrastText,
    fontSize: "1rem",
    zIndex: "100",
    marginTop: "25px",
  },
}));

function SignUp(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [confirmPasswordError, setPasswordConfirmError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailError, setEmailError] = useState(false);
  const { register, handleSubmit } = useForm();
  const [open, setOpen] = useState(false);
  let history = useHistory();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    history.push("/login");
  };

  const onSubmit = async (data) => {
    let username = data.email.toLowerCase();
    let password = data.password;
    let email = data.email.toLowerCase();
    let firstName = data.fname;
    let lastName = data.lname;

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email.toLowerCase())) {
      setEmailError(false);
    } else {
      setEmailError(true);
      setEmailErrorMessage("This email address is not in the correct format");
      return;
    }

    const pe = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d).{8,128}$/;
    if (pe.test(password)) {
      setPasswordConfirmError(false);
    } else {
      setPasswordConfirmError(true);
      setPasswordErrorMessage(
        "passwords needs to be at least 8 characters, and contains at least one large case, one lower case and one number"
      );
      return;
    }
    
    if (data.password !== data.confirmPassword) {
      setPasswordConfirmError(true);
      setPasswordErrorMessage("passwords do not match");
      return;
    } else {
      setPasswordConfirmError(false);
    }

    try {
      await Auth.signUp({
        username,
        password,
        attributes: { email },
      });
      setEmailError(false);
      let param = {
        emailAddress: username,
        firstName: firstName,
        lastName: lastName,
      };

      postUserInfo(param);

      handleClickOpen();
    } catch (err) {
      console.log(err);
      setEmailErrorMessage("This email address is taken");
      setEmailError(true);
    }
  };

  if (props.isUserLoggedIn === "loggedIn") {
    return <Redirect to="/revise" />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.gradient}></div>
      <div className={classes.container}>
        <Grid
          container
          direction={"column"}
          justify={"center"}
          alignItems={"center"}
        >
          <Typography variant={"h4"} className={classes.title}>
            Get Started, Join Chancery!
          </Typography>
          <form 
            className={classes.formContainer}
            noValidate 
            onSubmit={handleSubmit(onSubmit)}>
              <Hidden smUp>
                <div>
                  <Grid item>
                    <Typography className={classes.label}>First Name</Typography>
                    <TextField
                      inputProps={{
                        className: classes.textBox
                      }}
                      inputRef={register}
                      required
                      fullWidth
                      id="fname"
                      name="fname"
                      autoFocus
                      placeholder={"First"}
                    />
                  </Grid>
                  <br/>
                  <Grid item>
                    <Typography className={classes.label}>Last Name</Typography>
                    <TextField
                      inputProps={{
                        className: classes.textBox
                      }}
                      inputRef={register}
                      required
                      fullWidth
                      id="lname"
                      name="lname"
                      placeholder={"Last"}
                    />
                  </Grid>
                </div>
              </Hidden>
              <Hidden xsDown>
                <Grid container spacing={5} item>
                  <Grid item xs={6}>
                    <Typography className={classes.label}>First Name</Typography>
                    <TextField
                      inputProps={{
                        className: classes.textBox
                      }}
                      inputRef={register}
                      required
                      fullWidth
                      id="fname"
                      name="fname"
                      autoFocus
                      placeholder={"First"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.label}>Last Name</Typography>
                    <TextField
                      inputProps={{
                        className: classes.textBox
                      }}
                      inputRef={register}
                      required
                      fullWidth
                      id="lname"
                      name="lname"
                      placeholder={"Last"}
                    />
                  </Grid>
                </Grid>
              </Hidden>
            <br />
            <Grid item>
              <Typography className={classes.label}>Email</Typography>
              <TextField
                inputProps={{
                  className: classes.textBox
                }}
                inputRef={register}
                required
                error={emailError}
                helperText={emailError && emailErrorMessage}
                fullWidth
                type={"email"}
                id="email"
                name="email"
                autoComplete="email"
                placeholder={"Enter your email address"}
              />
            </Grid>
            <br />
            <Grid item>
              <Typography className={classes.label}>Password</Typography>
              <TextField
                inputProps={{
                  className: classes.textBox
                }}
                inputRef={register}
                required
                error={confirmPasswordError}
                helperText={confirmPasswordError && passwordErrorMessage}
                fullWidth
                type={"password"}
                id="password"
                name="password"
                placeholder={"********"}
              />
            </Grid>
            <br />
            <Grid item>
              <Typography className={classes.label}>
                Confirm Password
              </Typography>
              <TextField
                inputProps={{
                  className: classes.textBox
                }}
                error={confirmPasswordError}
                helperText={confirmPasswordError && passwordErrorMessage}
                inputRef={register}
                required
                fullWidth
                type={"password"}
                id="confirmPassword"
                name="confirmPassword"
                placeholder={"********"}
              />
            </Grid>
            <br />
            <Grid item>
              <Button
                className={classes.loginButton}
                type={"submit"}
                color="primary"
                variant={"outlined"}
              >
                Create Account
              </Button>
            </Grid>
          </form>
          <Typography className={classes.accountYet}>
            Already have an account? <Link href="/login">Sign In</Link>
          </Typography>
        </Grid>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ display: "flex" }}>
          <CheckCircleOutlineIcon
            style={{ color: "#21ce99", marginRight: "20px" }}
          />
          <DialogContentText>
            <span style={{ color: "#363636" }}>A verification email has been sent. Please verify your email.</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SignUp;
