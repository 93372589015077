/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-southeast-2",
  aws_cognito_identity_pool_id:
    "ap-southeast-2:39b32cc8-cb59-4672-95b7-19c6e3cc5529",
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id: "ap-southeast-2_Pmbx3i3bl",
  aws_user_pools_web_client_id: "2j2p8vkbtphpbo5k0499iqra82",
  oauth: {},
};

export default awsmobile;
