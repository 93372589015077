import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ExitToApp, Settings, ToggleOff, ToggleOn } from "@material-ui/icons";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

const StyledMenu = withStyles((theme) => ({
  paper: {
    borderRadius: 0,
    boxShadow: theme.boxShadow,
    backgroundColor: theme.palette.secondary.light,
  },
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const StyledListItem = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
  },
}))(ListItemText);

function AccountMenu(props) {
  const darkMode = props.darkMode;
  const anchorEl = document.getElementById(props.anchorEl);

  let history = useHistory();

  const logout = async () => {
    try {
      props.onClose();
      await Auth.signOut();
      props.updateAuthState("loggedOut");
      history.push("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <StyledMenu
      id="customized-menu"
      anchorEl={anchorEl}
      keepMounted
      disableAutoFocusItem
      open={Boolean(anchorEl)}
      onClose={props.onClose}
    >
      <StyledMenuItem onClick={() => props.setDarkMode(!darkMode)}>
        <ListItemIcon>
          {darkMode ? (
            <ToggleOn fontSize="small" />
          ) : (
            <ToggleOff fontSize="small" />
          )}
        </ListItemIcon>
        <StyledListItem primary={darkMode ? "Light Mode" : "Dark Mode"} />
      </StyledMenuItem>
      <Link to={"/settings"} style={{ textDecoration: "none" }}>
        <StyledMenuItem onClick={() => props.onClose()}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <StyledListItem primary="Settings" />
        </StyledMenuItem>
      </Link>
      <StyledMenuItem onClick={logout}>
        <ListItemIcon>
          <ExitToApp fontSize="small" />
        </ListItemIcon>
        <StyledListItem primary="Logout" />
      </StyledMenuItem>
    </StyledMenu>
  );
}

export default AccountMenu;
