import React, { useState, useEffect } from "react";
import { Grid, Checkbox, FormGroup, FormControlLabel, FormControl, FormLabel, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { updateFilters, resetFilters } from "../redux/actions";
import * as constants from "../redux/constants";
import allFalse from "../utils/allFalse";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  filterBox: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.borderRadius,
    boxShadow: theme.boxShadow,
    padding: "20px",
    height: "fit-content",
  },
  heading: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "1.25rem",
    paddingBottom: "5px",
    color: theme.palette.text.primary,
  },
  label: {
    fontSize: "1rem",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    marginRight: "1rem",
  },
  icon: {
    borderRadius: theme.borderRadius,
    borderStyle: "solid",
    width: "1rem",
    height: "1rem",
    "input:hover ~ &": {
      backgroundColor: theme.palette.type === "dark" ? "#6f6f6f" : "#e8e8e8",
    },
  },
  closeIcon: {
    position: "absolute",
    right: 0,
    padding: 0,
    zIndex: 100,
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.dark,
    borderStyle: "solid",
    "input:hover ~ &": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  labelContainer: {
    flexDirection: "column",
  },
  button: {
    borderRadius: theme.borderRadius,
    padding: theme.padding,
    backgroundColor: theme.palette.type === "dark" ? "#818181" : "#FFFFFF",
  },
  [theme.breakpoints.down("xs")]: {
    heading: {
      fontSize: "1rem",
    },
  },
}));

function StyledCheckbox(props) {
  const classes = useStyles();
  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="primary"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  );
}

function FilterBox(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const topics = constants.topics;
  const difficulties = constants.difficulties;
  const savedTopics = useSelector((state) => state.topics);
  const savedDifficulties = useSelector((state) => state.difficulties);
  const [checkedStates, setCheckedStates] = useState({
    ...savedTopics,
    ...savedDifficulties,
  });

  useEffect(() => {
    if (props.filterParam) {
      Object.keys(savedTopics).forEach(v => savedTopics[v] = false)
      dispatch(
        updateFilters(
          { ...savedTopics, [decodeURI(props.filterParam)]: true },
        )
      )
    }
  }, []);

  const applyTopicFilters = (event, topic) => {
    dispatch(
      updateFilters(
        { ...savedTopics, [topic]: event.target.checked },
        savedDifficulties
      )
    );
    event.target.blur();
  };

  const applyDifficultyFilters = (event, difficulty) => {
    dispatch(
      updateFilters(savedTopics, {
        ...savedDifficulties,
        [difficulty]: event.target.checked,
      })
    );
    event.target.blur();
  };

  const clearFilters = () => {
    if (!allFalse(checkedStates)) {
      dispatch(resetFilters());
    }
  };

  useEffect(() => {
    setCheckedStates({ ...savedTopics, ...savedDifficulties });
  }, [savedTopics, savedDifficulties]);

  const topicCheckBoxes = topics.map((topic) => (
    <FormControlLabel
      value={topic}
      control={<StyledCheckbox checked={checkedStates[topic]} />}
      label={
        <Typography className={classes.label}>
          {topic === "Discounted Cash Flow" ? "DCF" : topic}
        </Typography>
      }
      labelPlacement="end"
      key={topic}
      onChange={(event) => applyTopicFilters(event, topic)}
    />
  ));

  const difficultyCheckBoxes = difficulties.map((difficulty) => (
    <FormControlLabel
      value={difficulty}
      control={<StyledCheckbox checked={checkedStates[difficulty]} />}
      label={<Typography className={classes.label}>{difficulty}</Typography>}
      labelPlacement="end"
      key={difficulty}
      onChange={(event) => applyDifficultyFilters(event, difficulty)}
    />
  ));

  return (
    <Grid
      className={classes.filterBox}
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
    >
      {
        props.mobileView && (
          <Button 
          className={classes.closeIcon}
          disableRipple
          onClick={() => props.onClose()}>
            <CloseIcon />
          </Button>
        )
      }
      
      <Grid className={classes.labelContainer} item container>
        <Grid item>
          <FormControl component="fieldset">
            <FormLabel component="label" focused>
              <Typography className={classes.heading}>Topics:</Typography>
            </FormLabel>
            <FormGroup aria-label="position" row={false}>
              {topicCheckBoxes}
            </FormGroup>
          </FormControl>
        </Grid>
        <br />
        <Grid item>
          <FormControl component="fieldset">
            <FormLabel component="label" focused>
              <Typography className={classes.heading}>Difficulty:</Typography>
            </FormLabel>
            <FormGroup aria-label="position" row={false}>
              {difficultyCheckBoxes}
            </FormGroup>
          </FormControl>
        </Grid>

        <br />
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <Button
          className={classes.button}
          fullWidth={true}
          onClick={clearFilters}
        >
          <Typography className={classes.label}>Clear Filters</Typography>
        </Button>
      </Grid>
    </Grid>
  );
}

export default FilterBox;
