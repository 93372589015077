import React, { useState } from "react";
import { Button, Typography, Link, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    color: theme.palette.secondary.main,
  },
  gradient: {
    position: "absolute",
    width: "100%",
    height: "100vh",
    transform: "skewY(-10deg)",
    transformOrigin: "0%",
    background: "linear-gradient(-150deg, #212121 15%, #313131 55%, #3c5958 95%)",
  },
  container: {
    paddingTop: "50px",
    paddingBottom: "50px",
    paddingLeft: "100px",
    paddingRight: "100px",
    [theme.breakpoints.down('sm')]: {
      paddingLeft: "50px",
      paddingRight: "50px",
    },
    [theme.breakpoints.down('xs')]: {
      padding: 25,
    },
  },
  formContainer: {
    background: theme.palette.background.paper,
    borderRadius: theme.borderRadius,
    boxShadow: theme.boxShadow,
    padding: "50px",
    [theme.breakpoints.down('xs')]: {
      padding: "25px",
    },
    minWidth: "50%",
    zIndex: "100",
  },
  title: {
    color: "#fff",
    fontWeight: "bold",
    zIndex: "100",
    marginBottom: "25px",
    textAlign: "center",
    [theme.breakpoints.down('xs')]: {
      fontSize: "1.75rem",
    },
  },
  label: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  textBox: {
    backgroundColor: "#e5e5e5",
    color: theme.palette.secondary.main,
    paddingLeft: "5px",
  },
  loginButton: theme.button,
  forgotPassword: {
    color: theme.palette.secondary.light,
    display: "inline-block",
  },
  accountYet: {
    color: theme.palette.secondary.contrastText,
    fontSize: "1rem",
    zIndex: "100",
    marginTop: "25px",
  },
}));

function Login(props) {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const [error, setError] = useState(false);
  let history = useHistory();

  const onSubmit = async (data) => {
    let username = data.email;
    let password = data.password;
    try {
      await Auth.signIn(username, password);
      setError(false);
      props.updateAuthState("loggedIn");
      history.push("/");
    } catch (err) {
      setError(true);
    }
  };

  if (props.isUserLoggedIn === "loggedIn") {
    history.push("/");
  }

  return (
    <div className={classes.root}>
      <div className={classes.gradient}></div>
      <div className={classes.container}>
        <Grid
          container
          direction={"column"}
          alignItems={"center"}
        >
          <Typography className={classes.title} variant={"h4"}>
            Welcome Back!
          </Typography>
          <form
            className={classes.formContainer}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid item>
              <Typography className={classes.label}>Email</Typography>
              <TextField
                inputProps={{
                  className: classes.textBox
                }}
                inputRef={register}
                required
                error={error}
                helperText={error && "Wrong username or password"}
                fullWidth
                type={"email"}
                id="email"
                name="email"
                autoComplete="email"
                placeholder={"Enter your email address"}
              />
            </Grid>
            <br />
            <Grid item>
              <Typography className={classes.label}>Password</Typography>
              <TextField
                inputProps={{
                  className: classes.textBox
                }}
                inputRef={register}
                required
                fullWidth
                type={"password"}
                id="password"
                name="password"
                placeholder={"********"}
              />
            </Grid>
            <br />
            <Grid container item alignItems={"center"}>
              <Grid container item xs={12} justify="flex-start">
                <Typography>
                  <Link href={"/password"} className={classes.forgotPassword}>
                    Forgot Password?
                  </Link>
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Grid item>
              <Button
                className={classes.loginButton}
                type={"submit"}
                color="primary"
                variant={"outlined"}
              >
                Login
              </Button>
            </Grid>
          </form>
          <Typography className={classes.accountYet}>
            Don't have an account yet? <Link href="/signup">Sign Up</Link>
          </Typography>
        </Grid>
      </div>
    </div>
  );
}

export default Login;
