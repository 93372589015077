import React, { useState } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { ThemeProvider } from "@material-ui/styles";
import "./App.css";
import Main from "./Main.js";

function App() {
  const [darkMode, setDarkMode] = useState(true);

  const BREAKPOINTS = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
  };
  
  const breakpointsFull = createBreakpoints({
    values: {...BREAKPOINTS},
    keys: Object.keys(BREAKPOINTS),
  });

  const theme = createMuiTheme({
    breakpoints: breakpointsFull,
    palette: {
      type: darkMode ? "dark" : "light",
      text: {
        primary: darkMode ? "#f5f5f5" : "#404040",
      },
      primary: {
        main: "#21ce99",
        light: "#6fd1b3",
        dark: "#0a9168",
        contrastText: darkMode ? "#f9f9f9" : "#313131",
      },
      secondary: {
        main: "#363636",
        light: darkMode ? "#5f5f5f" : "#f5f5f5",
        dark: darkMode ? "#3f3f3f" : "#f9f9f9",
        contrastText: darkMode ? "#c9c9c9" : "#666666",
      },
      background: {
        default: darkMode ? "#313131" : "#e9e9e9",
        paper: "#f5f5f5",
      },
    },
    button: {
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: "1rem",
      borderRadius: "4px",
      borderColor: "#21ce99",
      border: "1px solid rgba(33, 206, 153, 0.5)",
      padding: "10px 20px 10px 20px",
      transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      color: "#21ce99",
      opacity: 1,
      "&:hover": {
          textDecoration: "none",
          backgroundColor: "#21ce99",
          color: "#ffffff",
      },
    },
    link: {
      textDecoration: "none",
      color: "#21ce99",
    },
    padding: "10px 20px 10px 20px",
    borderRadius: "4px",
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  });

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Main darkMode={darkMode} setDarkMode={setDarkMode} />
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
