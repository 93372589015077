import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { Button, Card, CardContent, CardActions, Collapse, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      width: "100%",
      marginRight: 0,
    },
    marginRight: 25,
    marginBottom: 25,
    backgroundColor: theme.palette.secondary.light,
  },
  title: {
    fontWeight: "bold",
  },
  subtitle: {
    marginBottom: 12,
    color: theme.palette.secondary.contrastText,
  },
  button: {
    backgroundColor: theme.palette.secondary.light,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default function SimpleCard(props) {
  const classes = useStyles();
  const {cardTitle, cardFilter, cardNumCards} = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h6" component="h4" className={classes.title}>
          {cardTitle}
        </Typography>
        <Typography className={classes.subtitle}>
          {cardNumCards} questions
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Link
            to={"/revise?filter="+cardTitle}
            style={{ textDecoration: "none" }}
          >
          <Button aria-label="add to favorites" className={classes.button}>
            <span>Practice</span>
          </Button>
        </Link>
        {/* <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="more actions"
        >
          <ExpandMoreIcon />
        </IconButton> */}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          
        </CardContent>
      </Collapse>
    </Card>
  );
}