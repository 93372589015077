import SvgIcon from "@material-ui/core/SvgIcon";
import React from "react";

function SavedIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M7 17.97l5-2.15 5 2.15V5H7z" opacity="1" color={"#21CE99"}/>
            <path d="M17 3H7c-1.1 0-2 .9-2 2v16l7-3 7 3V5c0-1.1-.9-2-2-2zm0 14.97l-5-2.14-5 2.14V5h10v12.97z" color={"#16A67A"}/>
        </SvgIcon>
    )
}

export default SavedIcon